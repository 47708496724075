import React from "react";
import { Link } from "react-router-dom";
import style from "./index.module.scss";
import facebookIcon from "../../assets/images/footer/facebook.svg";
import instagramIcon from "../../assets/images/footer/instagram.svg";
import linkedinIcon from "../../assets/images/footer/linkedin.svg";
import twitterIcon from "../../assets/images/footer/twitter.svg";
import youtubeIcon from "../../assets/images/footer/youtube.svg";
import logoLight from "../../assets/images/msa__logo.png";
const Footer = () => {
  const socialLinks = [
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/company/myserviceagent-msa-/",
      icon: linkedinIcon,
    },
    {
      name: "Instagram",
      link: "https://www.instagram.com/myserviceagent/",
      icon: instagramIcon,
    },
    {
      name: "Facebook",
      link: "https://web.facebook.com/myserviceagent_msa",
      icon: facebookIcon,
    },
    {
      name: "Twitter",
      link: "https://twitter.com/MyServiceAgent",
      icon: twitterIcon,
    },
    {
      name: "Youtube",
      link: "https://youtube.com/channel/UCqhfrxuA7Y4YbH47d6pDyYQ",
      icon: youtubeIcon,
    },
  ];

  const detailsLinks = [
    {
      name: "About us",
      link: "/about",
    },
    {
      name: "FAQ",
      link: "/faq",
    },
    {
      name: "Contact",
      link: "/contact-us",
    },
    {
      name: "Pricing",
      link: "/pricing",
    },
    {
      name: "Developers",
      link: "https://documentation.myserviceagent.net/",
    },
  ];

  const productsLinks = [
    {
      name: "Voice Survey",
      link: "/voice-survey",
    },
    {
      name: "Voice Business Messaging",
      link: "/business-messaging",
    },
    {
      name: "Voice OTP Authentication",
      link: "/otpauthentication",
    },

    {
      name: "Virtual Call Center",
      link: "/call-center",
    },
  ];

  const legalLinks = [
    {
      name: "Terms of Use",
      link: "/terms-of-service",
    },
    {
      name: "Privacy Policy",
      link: "/privacy-policy",
    },
  ];
  return (
    <>
      <footer>
        <div className={style["container"]}>
          <div className={style["footer__row"]}>
            {/* 
              -- the footer has a common structure and could be rewritten 
              
              to take advantage of react's declarative programming, 

              The next line should be changed to object.map(...) */}

            <div className={style["col"]}>
              <img src={logoLight} alt="msa" />
              {/* <p>
                MyServiceAgent is a technology solution that solves
                communication problems for businesses in Africa. Our goal is to
                help businesses gain trust and stay top of the mind of
                customers.
              </p> */}
            </div>

            <div className={style["col"]}>
              <p>Products</p>
              <ul className={style["products-list"]}>
                {productsLinks.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link}>
                      <span>{item.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className={style["col"]}>
              <p>Company</p>
              <ul className={style["contact-links"]}>
                {detailsLinks.map((item, index) => (
                  <li key={index}>
                    {item.name === "Developers" ? (
                      <Link to={{ pathname: item.link }} target="_blank">
                        <span> {item.name}</span>
                      </Link>
                    ) : (
                      <Link to={item.link}>
                        <span>{item.name}</span>
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className={style["col"]}>
              <p>Legal</p>
              <ul className={style["products-list"]}>
                {legalLinks.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link}>
                      <span>{item.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={style["col"]}>
              <p>Phone</p>
              <ul className={style["products-list"]}>
                <li>
                  <a href="tel:012701845">01-270-1845</a>
                </li>
                <li>
                  {" "}
                  <a href="tel:+2348026299730">0705 196 1983</a>
                </li>
              </ul>
            </div>
            <div className={style["col"]}>
              <p>Social</p>
              <ul className={style["social__links"]}>
                {socialLinks.map((item, id) => (
                  <li key={id}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.icon} alt={item.name} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className={style["footer__bottom--text"]}>
            {/* <div className={style["socials"]}>
              <ul className={style["social__links"]}>
                {socialLinks.map((item, id) => (
                  <li key={id}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                      <img src={item.icon} alt={item.name} />
                    </a>
                  </li>
                ))}
              </ul>
            </div> */}
            <div className={style["footer__copyright"]}>
              <span>
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    style={{ fill: "#f4f4f4" }}
                    d="M10 0.248779C15.52 0.248779 20 4.72878 20 10.2488C20 15.7688 15.52 20.2488 10 20.2488C4.48 20.2488 0 15.7688 0 10.2488C0 4.72878 4.48 0.248779 10 0.248779ZM10 5.24878C7.24 5.24878 5 7.48878 5 10.2488C5 13.0088 7.24 15.2488 10 15.2488C11.82 15.2488 13.413 14.2758 14.288 12.8208L12.573 11.7928C12.3031 12.2424 11.9201 12.6134 11.4621 12.8689C11.0041 13.1243 10.4871 13.2552 9.96274 13.2485C9.43837 13.2419 8.92491 13.0979 8.47355 12.8309C8.02218 12.5639 7.64868 12.1833 7.39029 11.727C7.13189 11.2706 6.99763 10.7546 7.00089 10.2302C7.00414 9.70575 7.1448 9.19136 7.40885 8.73828C7.67289 8.28519 8.05108 7.90923 8.50573 7.64788C8.96038 7.38653 9.47559 7.24892 10 7.24878C11.093 7.24878 12.05 7.83278 12.574 8.70578L14.288 7.67578C13.8437 6.93535 13.2153 6.32262 12.4638 5.89729C11.7123 5.47196 10.8635 5.24854 10 5.24878Z"
                    fill="#F4F4F4"
                  />
                </svg>
              </span>
              <p>
                {" "}
                MyServiceAgent {new Date().getFullYear()} . All rights reserved.
              </p>
            </div>

            {/* <p className={style["footer__privacy"]}>Privacy Policy</p> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
